$govuk-assets-path: "~govuk-frontend/govuk/assets/";
@import "~govuk-frontend/govuk/all";

// Support buttons as well as links
button.govuk-back-link {
  appearance: unset;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}

.govuk-panel--loading {
  background: govuk-colour("blue", $legacy: "blue");
}

.govuk-accordion__section-button {
  width: 100%;
}

:root {
  --amplify-primary-color: #000000;
  --amplify-primary-tint: #000000;
  --amplify-primary-shade: #000000;
}

.loading-root {
  display: block;
  text-align: center;
  clear: both;
}

.nav-float-right nav {
  display: inline-block;
  float: right;
}
